<template>
  <v-container fluid class="grey lighten-4">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Komunikat
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Szukaj" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="tableHeaders"
          :items="statements"
          :sort-by="['name', 'created']"
          :sort-desc="[false, true]"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Wszystkie komunikaty</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">Dodaj komunikat</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Dodaj komunikat</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            v-model="editedItem.type"
                            label="Typ komunikatu"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            outlined
                            v-model="editedItem.content"
                            label="Treść"
                          ></v-textarea>
                        </v-col>

                        <v-col cols="12">
                          <p>Wybierz do kiedy komunikat ma być wyświetlany użytkownikom</p>
                          <div class="text-center">
                            <v-date-picker
                              v-model="editedItem.expiry_on"
                              color="amber darken-1"
                              :first-day-of-week="1"
                              locale="pl-PL"
                            ></v-date-picker>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-alert class="col-12" v-if="message" type="error">
                          {{ message }}
                        </v-alert>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Anuluj</v-btn>
                    <v-btn color="blue darken-1" text @click="save">Dodaj</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <a :href="'tel:' + item.phone">{{ item.phone }}</a>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <a :href="'mailto:' + item.email">{{ item.email }}</a>
          </template>

          <template v-slot:[`item.content`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.content"
              @save="saveEdit(props.item)"
              @cancel="cancelEdit"
              @open="openEdit"
              large
              persistent
            >
              {{ props.item.content }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.content"
                  label="Edit"
                  single-line
                  counter
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import axios from 'axios';

  export default {
    props: {
      source: String,
    },
    data: () => ({
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      snack: false,
      snackColor: '',
      snackText: '',
      tableHeaders: [
        {
          text: 'LP',
          align: 'left',
          value: 'id',
        },
        {
          text: 'Typ',
          value: 'type',
        },
        {
          text: 'Treść',
          value: 'content',
        },
        {
          text: 'Data ważności',
          value: 'expiry',
        },
      ],
      statements: [],
      search: null,
      dialog: false,
      editedItem: {
        type: 'Ogłoszenie',
        content: null,
        expiry_on: new Date().toISOString().substr(0, 10),
      },
      defaultItem: {
        type: null,
        content: null,
        expiry_on: null,
      },
      message: null,
    }),
    methods: {
      saveEdit(item) {
        axios({
          url: this.$store.state.url + '/api/statements/update',
          data: {
            token: this.$store.state.token,
            params: {
              content: item.content,
            },
            id: item.id,
          },
          method: 'POST',
        })
          .then(() => {
            this.snack = true;
            this.snackColor = 'success';
            this.snackText = 'Zapisano zmiany';
          })
          .catch(e => {
            this.snack = true;
            this.snackColor = 'error';
            this.snackText = e.response.data.message;
          });
      },
      cancelEdit() {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Anulowano edycję';
      },
      openEdit() {
        this.snack = true;
        this.snackColor = 'info';
        this.snackText = 'Rozpoczęto edycję';
      },
      close: function () {
        this.dialog = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        }, 300);
      },
      save: function () {
        axios({
          url: this.$store.state.url + '/api/statements/create',
          data: {
            token: this.$store.state.token,
            params: this.editedItem,
          },
          method: 'POST',
        })
          .then(() => {
            window.location.reload();
          })
          .catch(e => {
            this.message = e.response.data.message;
          });
      },
    },
    created() {
      axios({
        url: this.$store.state.url + '/api/statements/get-all',
        data: {
          token: this.$store.state.token,
        },
        method: 'POST',
      }).then(resp => {
        for (let [i, s] of resp.data.statements.entries()) {
          let date = new Date(Date.parse(s.expiry_on));
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          let dt = date.getDate();

          if (dt < 10) dt = '0' + dt;
          if (month < 10) month = '0' + month;

          this.deliveryDate = `${year}-${month}-${dt}`;
          this.pickupDate = `${year}-${month}-${dt}`;

          this.statements.push({
            id: i + 1,
            type: s.type,
            content: s.content,
            expiry: `${dt}.${month}.${year}`,
          });
        }
      });
    },
  };
</script>
