var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-card-title',[_vm._v(" Komunikat "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.statements,"sort-by":['name', 'created'],"sort-desc":[false, true],"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Wszystkie komunikaty")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Dodaj komunikat")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Dodaj komunikat")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Typ komunikatu"},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Treść"},model:{value:(_vm.editedItem.content),callback:function ($$v) {_vm.$set(_vm.editedItem, "content", $$v)},expression:"editedItem.content"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Wybierz do kiedy komunikat ma być wyświetlany użytkownikom")]),_c('div',{staticClass:"text-center"},[_c('v-date-picker',{attrs:{"color":"amber darken-1","first-day-of-week":1,"locale":"pl-PL"},model:{value:(_vm.editedItem.expiry_on),callback:function ($$v) {_vm.$set(_vm.editedItem, "expiry_on", $$v)},expression:"editedItem.expiry_on"}})],1)])],1),_c('v-row',[(_vm.message)?_c('v-alert',{staticClass:"col-12",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Anuluj")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Dodaj")])],1)],1)],1)],1)]},proxy:true},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'tel:' + item.phone}},[_vm._v(_vm._s(item.phone))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:' + item.email}},[_vm._v(_vm._s(item.email))])]}},{key:"item.content",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.content,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "content", $event)},"update:return-value":function($event){return _vm.$set(props.item, "content", $event)},"save":function($event){return _vm.saveEdit(props.item)},"cancel":_vm.cancelEdit,"open":_vm.openEdit},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":""},model:{value:(props.item.content),callback:function ($$v) {_vm.$set(props.item, "content", $$v)},expression:"props.item.content"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.content)+" ")])]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }